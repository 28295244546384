if(typeof sb == 'undefined'){
	window.sb = {data:{},app:{}};
}

sb.util = {
	log: function (x,y) {
		if (window.console && console.log)
			console.log( x + ' - ' + y);
	},

	dump: function(x,y) {
		if (window.console && console.dir ){
			console.log(x);
			console.dir(y);
		}
	},

	queryParam: function(name)  {
		var name = name.replace(/[\[]/,'\\\[').replace(/[\]]/,'\\\]');
		var regexS = '[\\?&]'+name+'=([^&#]*)';
		var regex = new RegExp( regexS );
		var results = regex.exec( window.location.href );
		if( results === null )
			return '';
		else
			return decodeURIComponent(results[1].replace(/\+/g, ' '));
	},
	
	xhr: function(o){
		var options = {
			type: 'POST',
			dataType: 'json',
			show_loading: true
		};
		$.extend(options, o);
		if(options.show_loading === true){
			$('#loading-row').show();
		}
		var promise = $.ajax(options);
		return promise;
	},

	truncate: function(data){
		var count = $( data.element ).text().split(' ');
		if(count.length > data.count){
			var expr = new RegExp('(([^\\s]+\\s+){' + data.count + '}).+');
			$( data.element ).html($( data.element ).text().replace(expr, '$1...'));
			$( data.read_more ).show();
		}
	},
	
	delay: function(callback, ms){
		clearTimeout (sb.data.timer);
		sb.data.timer = setTimeout(callback, ms);
	},	
	
	isInteger: function(n) {
		return n === +n && n === (n|0);
	},

	scrollTo: function(o){
		var options = {
			duration: 1500,
			offset: 80,
			container: 'html, body',
			nudge: false
		};
		$.extend(options, o);
		sb.data.options = options;
		$(options.container).animate({scrollTop: $( options.el ).offset().top - options.offset}, {
			duration: options.duration,
			complete: function(){
				if(sb.data.options.nudge){
					// sb.util.log('nudging');
					sb.util.delay(function(){	
						$(sb.data.options.container).scrollTop($(sb.data.options.container).scrollTop() + 1);
					}, 300);
				}
			}
		});
	},

	equalHeight: function(el){
		var tallest = 0;
		$(el).removeAttr('style');
		$(el).each(function(i,e){
			var $e = $(e);
			tallest = ($e.height() > tallest) ? $e.height() : tallest;
		});
		$(el).height(tallest);
	},

	toggler: function(){
		$('.sb-toggler').unbind('click').click(function(){
			var $e = $(this);
			if ( $e.data('keepOpen') ) {
				$($e.data('target')).removeClass('hide');
			} else {
				$($e.data('target')).toggleClass('hide');
			}
		});
	},

	trigger: function(){
		$('.sb-trigger').unbind('click').click(function(){
			var $e = $(this);
			$($e.data('trigger')).click();
		});
	},

	xhrAlert: function(options){
		var o = {
			el: '.sb-xhr-alert',
			html : 'This is the message',
			fade : 0,
			type : 'alert-info'
		};

		$.extend(o, options);
		
		$(o.el).addClass(o.type).html(o.html).slideDown();

		if(o.fade > 0){
			sb.util.delay(function(){
				$(o.el).slideUp();
			}, o.fade );
		}
	},
	
	scrollbarWidth: function(){
		  var test = document.createElement('div');
		  test.style.cssText = 'width:100px;height:100px;overflow:scroll !important;position:absolute;top:-9999px';
		  document.body.appendChild(test);
		  var result = test.offsetWidth - test.clientWidth;
		  document.body.removeChild(test);
		  return result;
	},
	
	isInView : function (options) {

		var o = {
			element: '.sb-load-more',
			offset: 80,
			mode: 'before'
		};
		var response = [false, 'entering','visible','exiting','exited'];
		var view = 0;
		$.extend(o, options);
		var win = $(window);
		if(typeof o.element === 'object'){
			var obj = o.element;
		} else {
			var obj = $(o.element);
		}
		if(typeof o.child === 'object'){
			var child = o.child;
		} else {
			var child = $(o.child);
		}
		if(obj.length > 0){
			var scrollPosition = win.scrollTop();
			var visibleArea = win.scrollTop() + win.height();
			var objBeginPos = (obj.offset().top);
			var objEndPos = (obj.offset().top + obj.outerHeight());
			var childEndPos = (obj.offset().top + obj.outerHeight() - o.offset ) - child.outerHeight() ;
			objEndPos = (o.mode == 'before') ? objEndPos - o.offset : objEndPos + o.offset;
			objBeginPos = (o.mode == 'image') ? objEndPos : objBeginPos;
		}

		if(visibleArea >= objBeginPos){
			view = 1;
		}
		if(visibleArea >= objBeginPos && scrollPosition <= objEndPos){
			view = 2;
		}
		if (scrollPosition >= childEndPos ){
			view = 3;
		}
		if (scrollPosition == objEndPos){
			view = 4;
		}
		if (scrollPosition > objEndPos){
			view = 0;
		}


		return response[view];
	}
};

$.fn.serializeObject = function(){
	var o = {};
	var a = this.serializeArray();
	$.each(a, function() {
		if (o[this.name] !== undefined) {
			if (!o[this.name].push) {
				o[this.name] = [o[this.name]];
			}
			o[this.name].push(this.value || '');
		} else {
			o[this.name] = this.value || '';
		}
	});
	return o;
};


