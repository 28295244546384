//--------------------------------------------|
// JQ.JS
//--------------------------------------------|
// Basic DOM Manipulations using jQuery

// CONSTANTS
// ---------------------------------|


$(function(){

    // Check user's position on page -----------------------------||
    $(document).scroll(function() {
        if($(window).scrollTop() > 0) {
            $('body').addClass('scrolling');
        }
        else {
            $('body').removeClass('scrolling');
        }
    })

    // Smooth scroll to point on page -----------------------------||
    // Apply "active" class when appropriate
    $('.scroll-to').click(function(e){
        e.preventDefault();
        var headerHeight = $('#header').outerHeight(); // grab the header's height
        var target = $(this).attr('href');
        var position = $(target).offset().top;

        $('body, html').animate({
            // Offset by twice the height of the header
            scrollTop: position + 1
        }, 500)

        // $('.nav-main a').removeClass('active');
        // $(this).addClass('active');
    });

    // Apply scroll-to class to anchors on the home page only -----------------------------||
    if($('body').hasClass('page-home')){
        $('.nav-main .easy').addClass('scroll-to');
        $('.nav-main .smart').addClass('scroll-to');
        $('.nav-main .good').addClass('scroll-to');
        $('.menu-mobile .easy').addClass('scroll-to');
        $('.menu-mobile .smart').addClass('scroll-to');
        $('.menu-mobile .good').addClass('scroll-to');
    }

    if($('body').hasClass('page-home')) {
        // Apply "active" class dynamically on scroll
        $(document).scroll(function() {
            var headerHeight = $('#header').outerHeight(); // grab the header's height
            var positionEasy    = $('#easy').offset().top;
            var positionSmart   = $('#smart').offset().top;
            var positionGood    = $('#good').offset().top;

            // check positioning between sections
            if($(window).scrollTop() < (positionEasy)) {
                $('.nav-main a').removeClass('active');
            }
            else if($(window).scrollTop() > (positionEasy) && $(window).scrollTop() < (positionSmart ) ) {
                $('.nav-main a').removeClass('active');
                $('a.easy').addClass('active');
            }
            else if($(window).scrollTop() > (positionSmart) && $(window).scrollTop() < (positionGood)) {
                $('.nav-main a').removeClass('active');
                $('a.smart').addClass('active');
            }
            else if($(window).scrollTop() > (positionGood)) {
                $('.nav-main a').removeClass('active');
                $('a.good').addClass('active');
            }
        })
    }

    var toggleMobileMenu = function(){
        $('.mobile-menu-button').toggleClass('active');
        $('.menu-mobile').toggleClass('active');
        $('body').toggleClass('menu-open');
        $('.mobile-menu-button .text').text(
            $('.mobile-menu-button .text').text() == 'Menu' ? 'Close' : 'Menu'
        );
    }

    // Mobile menu -----------------------------||
    $('.mobile-menu-button').click(function() {
        toggleMobileMenu();
    })

    $('.menu-mobile a').click(function() {
        toggleMobileMenu();
    })

    // Instantiate library for slight parallex scrolling effect -----------------------------||
    var rellax = new Rellax('.rellax', {
    })

    // Fixing click on form fields -----------------------------||
    $('.form-line label').click(function() {
        $(this).prevAll('.input').addClass('active');
        $(this).prevAll('.input').focus();
    })

    $('.form-line .input').focus(function() {
        $(this).addClass('active');
    })

    $('.form-line .input').click(function() {
        $(this).addClass('active');
    })

    $('.form-line .input').blur(function() {
        if($(this).val() == '')
            $(this).removeClass('active');
    })

    // Character Counter -----------------------------||
    var maxLength = 300;
    $('.input-character-tracker').keyup(function() {
        var length = $(this).val().length;
        var length = maxLength - length;
        $('.counter-length').text(length);
    })


    // Play the video in a modal -----------------------------||
    $('.icon-play').click(function() {
        $('body').addClass('modal-open');
        $('.modal-background').addClass('active');
        $('.modal-container').addClass('active');
        $('#video').get(0).play();
    })

    $('.modal-background').click(function() {
        closeVideo()
    })

    $('.modal-close').click(function() {
        closeVideo();
    })

    $(document).keyup(function(e) {
        if(e.keyCode === 27) {
            closeVideo()
        }
    })

    function closeVideo() {
        $('body').removeClass('modal-open');
        $('.modal-background').removeClass('active');
        $('.modal-container').removeClass('active');
        $('#video').get(0).pause();
    }

    // Initialize AOS
    AOS.init({
        disable: 'mobile'
    });

    // Input masking
    var Inputmask = require('inputmask');

    Inputmask({"mask": "(999) 999-9999"}).mask($('.field-tel'));
    Inputmask("9-a{1,3}9{1,3}").mask($('.field-tel'));
    Inputmask("9", { repeat: 10 }).mask($('.field-tel'));

});
